import React, {useState} from 'react'

import OrangeBorderButton from "../OrangeBorderButton/OrangeBorderButton";
import OrangeButton from "../OrangeButton/OrangeButton";

import LogoIcon from '../../images/logo.png'

import CartIcon from '../../images/cart.svg'

import './Header.scss';
import {DiscordIcon} from "../../images/SVGElements/DiscordIcon";
import {TwitterIcon} from "../../images/SVGElements/TwitterIcon";






function Header() {
    const [searchText, setSearchText] = useState('');

    return (
        <header className="header">
            <div className="header-container">
                <div className="header-left">
                    <a href={'#'}>
                        <div className="header-left_logo">
                            <img src={LogoIcon} alt=""/>
                        </div>
                    </a>
                    {/*<div className="header-left_search">*/}
                    {/*    <input type="text" placeholder={'Search collections'} value={searchText}/>*/}
                    {/*    <a href={'#'}>*/}
                    {/*        <OrangeBorderButton width={136} link={'#'}>Collections</OrangeBorderButton>*/}
                    {/*    </a>*/}
                    {/*</div>*/}
                </div>
                <div className="header-right">
                    <div className="header-right_logIn">
                        {/*<OrangeBorderButton>Your NFTs</OrangeBorderButton>*/}
                        <OrangeBorderButton width={125} link={'#'}>Join Airdrop</OrangeBorderButton>

                        <div className="icon-discord">
                            <a href="https://discord.gg/347847cnDM"><DiscordIcon /></a>
                        </div>
                        <div className="icon-twitter">
                            <a href="https://twitter.com/AlchemySwap">
                                <TwitterIcon />
                            </a>
                        </div>
                        {/*<OrangeButton>Connect</OrangeButton>*/}
                    </div>
                    {/*<div className="header-right_cart">*/}
                    {/*    <img src={CartIcon} alt=""/>*/}
                    {/*</div>*/}
                </div>
            </div>
        </header>
    );
}

export default Header;
