import './App.css';
import MainPageComp from "./page/MainPageComp";
import Header from "./component/Header/Header";


function App() {
  return (
    <div className="App">
        <Header />
        <MainPageComp />
    </div>
  );
}

export default App;
