import React from 'react';
import './OrangeBorderButton.scss';
import {Link} from "react-router-dom";

const OrangeBorderButton = ({ children, onClick = null, className = '' , width = 115, height = 33, link = null }) => (
    <a href={link}>
        <button disabled={className === 'next-btn'} className={`orange-border-button ${className}`} onClick={onClick} style={{width: `${width}px`, height: `${height}px`, marginRight: '0'}}>
            {children}
        </button>
    </a>
);

export default OrangeBorderButton;
